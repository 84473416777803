import React, { useCallback } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Logo from 'components/modules/base/Logo'
import Head from 'components/modules/base/Head'

const Login = dynamic(() => import('components/modules/authorize/Login.jsx').then((mod) => mod.default), {
  ssr: false,
})

const PageAuthorize = React.memo(() => {
  const router = useRouter()

  const handleLogin = useCallback(() => {
    router.replace('/me/dashboard')
  }, [])

  const handleSwitchToRegister = useCallback(() => {
    router.replace('/auth/register')
  }, [])

  return (
    <div className="flex flex-1 flex-col items-center pt-12 lg:pt-24">
      <Head title="账号登录｜简约简历" />
      <Logo size="large" withText />
      <div className="mt-6 mb-[5vh] self-stretch">
        <Login onAuthorize={handleLogin} onRequestRegister={handleSwitchToRegister} />
      </div>
    </div>
  )
})

PageAuthorize.options = {
  layoutClassName: 'h-screen bg-gray-200/70',
  withNavBar: false,
}

export default PageAuthorize
